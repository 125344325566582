/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 public/models/portfolio_rig.glb 
*/

import React, { useLayoutEffect, useRef } from 'react'
import * as THREE from 'three';
import gsap from 'gsap';
import { useGLTF, useScroll } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';

export const FLOOR_HEIGHT = 2;
export const NB_FLOORS = 3;

export function Rig(props) {
  const { nodes, materials } = useGLTF('./models/portfolio_rig.glb')
  const ref = useRef();
  const tl = useRef();
  const rtl = useRef();
  const skullRef = useRef();
  const torsoRef = useRef();
  const headphoneRef = useRef();
  const headRef = useRef();

  const start_rotation_x = -Math.PI / 2;
  const start_rotation_y = -Math.PI / 4;

  const scroll = useScroll();

  useFrame((state) => {
    tl.current.seek(scroll.offset * 5/2 * tl.current.duration());
    rtl.current.seek(scroll.offset * rtl.current.duration());

    if (scroll.range(1/3, 2/3) > 0) {
      headRef.current.rotation.x = THREE.MathUtils.lerp(headRef.current.rotation.x, -(state.pointer.y * Math.PI) / 20, 0.12);
      headRef.current.rotation.y = THREE.MathUtils.lerp(headRef.current.rotation.y, (state.pointer.x * Math.PI) / 8, 0.12);
      torsoRef.current.rotation.x = THREE.MathUtils.lerp(torsoRef.current.rotation.x, -(state.pointer.y * Math.PI) / 50, 0.095);
      torsoRef.current.rotation.y = THREE.MathUtils.lerp(torsoRef.current.rotation.y, (state.pointer.x * Math.PI) / 20, 0.095);
    } else {
      ref.current.rotation.x = THREE.MathUtils.lerp(ref.current.rotation.x, start_rotation_x - (state.pointer.y * Math.PI) / 20, 0.1);
      ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, start_rotation_y + (state.pointer.x * Math.PI) / 3, 0.1);
    }
  });

  useLayoutEffect(() => {
    tl.current = gsap.timeline();
    rtl.current = gsap.timeline();

    //vertical animation
    tl.current.to(
      ref.current.position, 
      {
        duration: 2,
        y: FLOOR_HEIGHT * (NB_FLOORS - 1) * 0.23,
      },
      0
    );

    //background animation
    tl.current.fromTo(
      ".background-gradient",
      {
        opacity: 0.4
      },
      {
        opacity: 0,
        duration: 2,
      },
      0
    );

    // full timeline background animation
    rtl.current.to(
      ref.current.position,
      {
        duration: 2.7,
        x: 0,
      },
      2.8
    );

    rtl.current.to(
      ref.current.rotation,
      {
        duration: 2.7,
        x: start_rotation_x,
        y: 0,
      },
      2.8
    );

    rtl.current.fromTo(
      ".background-gradient-color",
      {
        opacity: 0
      },
      {
        opacity: 1,
        duration: 2.8,
      },
      2.9
    );

    rtl.current.fromTo(
      ".nav",
      {
        backgroundColor: "rgba(0, 0, 0, 1)",
        boxShadow: "inset 0 -1px #1a1a1a",
      },
      {
        backgroundColor: "rgba(0, 0, 0, 0)",
        boxShadow: "inset 0 -1px rgba(0, 0, 0, 0)",
        duration: 0.3,
      },
      2.7
    );

    // TORSO
    tl.current.from(
      torsoRef.current.position,
      {
        duration: 2.4,
        x: -3,
        y: -2.5,
        z: -3,
      },
      0
    );
    tl.current.from(
      torsoRef.current.rotation,
      {
        duration: 2.2,
        x: -Math.PI / 8,
        y: Math.PI / 3,
        z: Math.PI / 5,
      },
      0
    );

    // SKULL
    tl.current.from(
      skullRef.current.position,
      {
        duration: 2,
        x: 1,
        y: -1.8,
        z: 0.5,
      },
      0
    );
    tl.current.from(
      skullRef.current.rotation,
      {
        duration: 2,
        y: Math.PI / 5,
        z: -Math.PI / 5,
      },
      0
    );

    // HEADPHONES
    tl.current.from(
      headphoneRef.current.position,
      {
        duration: 2.4,
        y: -5,
      },
      0
    );
    tl.current.from(
      headphoneRef.current.rotation,
      {
        duration: 2.4,
        x: -Math.PI / 4,
      },
      0
    );

    // RIG
    tl.current.from(
      ref.current.position,
      {
        duration: 2.4,
        x: 0,
      },
      0
    );
    tl.current.from(
      ref.current.rotation,
      {
        duration: 2.4,
        y: -3 * Math.PI / 7.5,
      },
      0
    );
    
  }, [start_rotation_x]);

  return (
    <group {...props} dispose={null} ref={ref} position={[10, 0, 0]} rotation={[start_rotation_x, start_rotation_y, 0]}>
      <group position={[0, -3.256, -0.762]}>
        <group ref={torsoRef}>
          <mesh geometry={nodes.Torso.geometry} material={materials.Torso} scale={[0.582, 2.718, 0.434]} />
        </group>
      </group>

      <group ref={headRef}>
        <group position={[0, 2.755, 0.762]}>
          <group ref={skullRef}>
            <mesh geometry={nodes.Skull.geometry} material={materials.Skull} scale={[1.956, 2, 2]} />
          </group>
        </group>

        <group position={[1.385, 6.17, 0.828]} scale={[2.248, -0.3, 0.372]}>
          <group ref={headphoneRef}>
            <mesh geometry={nodes.Headphones.geometry} material={materials.Headphone} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('./models/portfolio_rig.glb')
