import { Scroll, useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useState, useRef, useEffect } from "react";
import gsap from 'gsap';
import { useGSAP } from "@gsap/react";

// components
import HoverButton from "./HoverButton";
import Carousel from "./Carousel";
import Playlist from "./Playlist";
import MobilePlaylist from "./MobilePlaylist";

// icons
import { HiArrowSmDown } from "react-icons/hi";
import { VscGithubInverted } from "react-icons/vsc";
import { FaLinkedin } from "react-icons/fa";


const Section = (props) => {
    return (
        <section className="content" style={{opacity: props.opacity, height: props.height}}>
                {props.children}
        </section>
    );
};

export const Overlay = ({ isPlaying, setIsPlaying }) => {

    const scroll = useScroll();
    const [opacityFirstSection, setOpacityFirstSection] = useState(1);
    const [opacitySecondSection, setOpacitySecondSection] = useState(1);
    const [opacityThirdSection, setOpacityThirdSection] = useState(1);
    const [isMobile, setIsMobile] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);
    const arrowRef = useRef();
    const tl = useRef();

    useFrame(() => {
        setOpacityFirstSection(1 - scroll.range(0, 1/3));
        setOpacitySecondSection(scroll.range(0.2, 0.3) - scroll.range(0.5, 0.3));
        setOpacityThirdSection(scroll.range(2/3, 1/3));
    });

    useEffect(() => {
        const handleResize = () => {
            const w = window.innerWidth;
            const h = window.innerHeight
            setHeight(h);
            //console.log(w);
            if (w <= 1024) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useGSAP(() => {
        tl.current = gsap.timeline({ repeat: -1, paused: false });
        tl.current.to(arrowRef.current, { y: -5, duration: 0.3, ease: 'power1.out' });
        tl.current.to(arrowRef.current, { y: 0, duration: 0.3, ease: 'power1.in' });
        tl.current.to(arrowRef.current, { y: 5, duration: 0.3, ease: 'power1.out' });
        tl.current.to(arrowRef.current, { y: 0, duration: 0.3, ease: 'power1.in' });
    })

    return (
        <Scroll html>
            <Section opacity={opacityFirstSection} height={height}>
                <div className="center-card no-select">
                    {/*<p style={{fontFamily: 'Lovan', fontSize: 100}}>A</p>*/}
                    <Carousel />
                    <div style={{
                        position: 'absolute',
                        bottom: '0',
                        right: '0',
                        marginRight: '8rem',
                        marginBottom: '4rem',
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 'large',
                            gap: '5px',
                        }}>
                            <p>SCROLL</p>
                            <div ref={arrowRef}>
                                <HiArrowSmDown size={24}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            <Section opacity={opacitySecondSection} height={height}>
                <div className="left-card">
                    <div className="card-content" style={{
                        marginTop: "-10rem",
                    }}>
                        <h1>Hi, I'm Jamie</h1>
                        <p>
                        I'm an ambitious student with a passion for building engaging user experiences. <br/>
                        I started my journey in game development, and am now looking for opportunities to further grow as a developer!<br/>
                        </p>
                        <div style={{
                            paddingBlock: "1rem",
                            display: "flex",
                            gap: "1rem",
                        }}>
                            <HoverButton href="https://github.com/jamiechareunsouk" target="_blank">
                                <VscGithubInverted className="icon" size={50} fill="white"/>
                            </HoverButton>
                            <HoverButton href="https://linkedin.com/in/jachareu" target="_blank">
                                <FaLinkedin className="icon" size={50} fill="white"/>
                            </HoverButton>
                        </div>
                    </div>
                </div>
            </Section>
            <Section opacity={opacityThirdSection} height={height}>
                {/*
                <div className="center-card">
                    <Carousel />
                </div>
                */}
                <div className="center-card">
                    {
                        isMobile ?
                        <MobilePlaylist isPlaying={isPlaying} setIsPlaying={setIsPlaying}/> :
                        <Playlist isPlaying={isPlaying} setIsPlaying={setIsPlaying}/>
                    }
                </div>
            </Section>
        </Scroll>
    );
};