import React, { useRef, useState, useEffect } from 'react';
import { PerspectiveCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import './styles.css';

// assets
import BgTexture from './assets/dark_texture.png';

// components
import Navbar from './components/Navbar';
import Experience from './components/Experience';

function App() {
  const bgRef = useRef();
  const [FOV, setFOV] = useState(70);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      const w = window.innerWidth;
      const h = window.innerHeight;
      //console.log(w);
      if (w <= 768) {
        setFOV(100);
      } else {
        setFOV(70);
      }
      setWidth(w);
      setHeight(h);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useGSAP(() => {
    gsap.to(bgRef.current, {opacity: 0, duration: 0.7, ease: "power4.in"});
  });

  return (
    <div style={{ width: width, height: height, overflow: "hidden" }}>
      <div className='canvas'>
        <Canvas>
          <PerspectiveCamera position={[0, 15, 0]} rotation={[-Math.PI / 2, 0, 0]} fov={FOV} makeDefault={true} />
          <Experience />
        </Canvas>
      </div>
      <Navbar />
      <div className='background' ref={bgRef} />
      <img className='background-texture' src={BgTexture} alt="" />
      <div className='background-gradient' />
      <div className='background-gradient-color' />
    </div>
  );
}

export default App;