import { useState, useLayoutEffect, useEffect, useRef, useCallback, useMemo } from "react";
import { gsap } from "gsap";

// images
import PythonIcon from "../assets/slides/Python.png";
import JSIcon from "../assets/slides/JS.png";
import ReactIcon from "../assets/slides/React_Icon.png";
import LuaIcon from "../assets/slides/Lua.png";
import HTMLIcon from "../assets/slides/HTML5.png";
import FirebaseIcon from "../assets/slides/Firebase.png";
import CSSIcon from "../assets/slides/CSS.png";
import AWSIcon from "../assets/slides/AWS.png";
import AzureIcon from "../assets/slides/Azure.png";
import RIcon from "../assets/slides/R.png";

// icons
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { IoPlayBack } from "react-icons/io5";
import { IoPlayForward } from "react-icons/io5";

const Song = (props) => {
    return (
        <button 
            className="song no-select"
            onClick={() => {
                props.setter("SetSongPercent", 0);
                props.setter("Selected", props.title);
                props.setter("SelectedSubtitle", props.subtitle);
                props.setter("SelectedSrc", props.src);
                props.setter("SelectedDesc", props.desc);
                props.setter("SongNum", props.index);
                props.setter("IsPlaying", true);
                props.setter("ChangeBackground", props.color);
            }}
        >
            <img
                src={props.src}
                alt={props.title}
                style={{
                    height: "100%",
                }}
            />
            <div>
                <p
                    style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: (props.selected === props.title) ? "#ab3dff" : "white"
                    }}
                >
                    {props.title}
                </p>
                <p style={{
                    color: "#a3a3a3",
                    fontSize: "16px"
                }}>
                    {props.subtitle}
                </p>
            </div>
       </button>
    );
};

export default function Playlist({ isPlaying, setIsPlaying }) {
    const [selected, setSelected] = useState("HTML5");
    const [selectedSubtitle, setSelectedSubtitle] = useState("Language");
    const [selectedSrc, setSelectedSrc] = useState(HTMLIcon);
    const [selectedDesc, setSelectedDesc] = useState("Learned HTML5 through personal web projects.");
    const songs = useMemo(() => [
        {
            title: "HTML5",
            subtitle: "Language",
            src: HTMLIcon,
            desc: "Learned HTML5 through personal web projects.",
            color: "linear-gradient(to right top, #ff7230, #ff7230, #ff7230, #ff7230, #ff7230)",
        },
        {
            title: "CSS",
            subtitle: "Language",
            src: CSSIcon,
            desc: "Learned CSS styling through personal web projects.",
            color: "linear-gradient(to bottom, #1f3b87, #3b5594, #576ea0, #7588ab, #96a2b5)",
        },
        {
            title: "JavaScript",
            subtitle: "Language",
            src: JSIcon,
            desc: "Learned JavaScript through personal web projects and modding.",
            color: "linear-gradient(to right bottom, #000000, #3a1c1e, #6e3326, #9b5420, #b98000)",
        },
        {
            title: "React.js",
            subtitle: "Language",
            src: ReactIcon,
            desc: "Learned React.js through personal web projects.",
            color: "linear-gradient(to left bottom, #ff6d6d, #e69e47, #b2c761, #71e4ac, #43f6ff)",
        },
        {
            title: "Lua",
            subtitle: "Language",
            src: LuaIcon,
            desc: "Learned Lua through modding and game development.",
            color: "linear-gradient(to left bottom, #3b0000, #51001a, #620038, #640063, #410097)",
        },
        {
            title: "Python",
            subtitle: "Language",
            src: PythonIcon,
            desc: "Learned Python through personal web projects and scripting.",
            color: "linear-gradient(to bottom, #0486cb, #4785c1, #6084b7, #7184ad, #7c85a3)",
        },
        {
            title: "AWS",
            subtitle: "Cloud Service",
            src: AWSIcon,
            desc: "Experience using AWS for powering full-stack web applications.",
            color: "linear-gradient(to bottom, #ffffff, #e0e0e0, #c2c2c2, #a5a5a5, #898989)",
        },
        {
            title: "Azure",
            subtitle: "Cloud Service",
            src: AzureIcon,
            desc: "Knowledge of Azure cloud computing fundamentals and experience building Azure AI pipelines.",
            color: "linear-gradient(to bottom, #5c7483, #536776, #4a5b69, #414f5c, #38434f)",
        },
        {
            title: "Firebase",
            subtitle: "Cloud Service",
            src: FirebaseIcon,
            desc: "Experience using Firebase for user authentication.",
            color: "linear-gradient(to left bottom, #be8eb6, #d27f9d, #dc7478, #d5734c, #bb7b1a)"
        },
        {
            title: "RStudio",
            subtitle: "Language",
            src: RIcon,
            desc: "Experience using RStudio for statistical analysis.",
            color: "linear-gradient(to bottom, #ffffff, #e0e0e0, #c2c2c2, #a5a5a5, #898989)",
        },
    ], []);
    const [songNum, setSongNum] = useState(0);
    const num_songs = songs.length - 1;
    const songTime = 20; // song time in seconds
    const songPercent = useRef(0);
    const [songPercentState, setSongPercentState] = useState(0);
    const songsPlaying = useRef(0);

    const setter = (type, val) => {
        switch(type) {
            case "Selected": setSelected(val);
                break;
            case "SelectedSubtitle": setSelectedSubtitle(val);
                break;
            case "SelectedSrc": setSelectedSrc(val);
                break;
            case "SelectedDesc": setSelectedDesc(val);
                break;
            case "SongNum": setSongNum(val);
                break;
            case "IsPlaying": setIsPlaying(val);
                break;
            case "ChangeBackground": changeBackground(val, true);
                break;
            case "SetSongPercent": 
                songPercent.current = val;
                setSongPercentState(val);
                break;
            default:
                console.log("missing params");
        }
    };

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.set(".background-gradient-color", { backgroundColor: "#000000" });
        });

        return () => ctx.revert();
    }, []);

    const skipSong = useCallback((dir) => {
        let i = songNum;
        if (dir === "forward") {
            if (songNum >= num_songs) {
                setSongNum(0);
                i = 0;
            } else {
                setSongNum(songNum + 1);
                i += 1;
            }
        } else if (dir === "backward") {
            if (songNum <= 0) {
                setSongNum(num_songs);
                i = num_songs;
            } else {
                setSongNum(songNum - 1);
                i -= 1;
            }
        } else {
            return;
        }
        songsPlaying.current = 0;
        songPercent.current = 0;
        setSongPercentState(0);
        setSelected(songs[i].title);
        setSelectedSubtitle(songs[i].subtitle);
        setSelectedSrc(songs[i].src);
        setSelectedDesc(songs[i].desc);
        setIsPlaying(true);
        changeBackground(songs[i].color, true);
    }, [num_songs, setIsPlaying, songNum, songs]);

    useEffect(() => {
        //console.log("isplaying:", isPlaying, "songsplaying:", songsPlaying.current);
        const changeSongPercent = () => {
            //console.log("huh?");
            if (songPercent.current < 100) {
                songPercent.current += (100 / songTime);
            } else if (songPercent.current >= 100) {
                skipSong("forward");
            }
            setSongPercentState(songPercent.current);
        };

        const songLoop = (songsPlaying, songsPlayingIndex) => {
            //console.log("loop");
            songsPlaying.current += 1;
            if (songsPlaying.current > 1) {
                changeSongPercent();
            }
            setTimeout(()=> {
                //console.log(songsPlaying.current, songsPlayingIndex + 1);
                if (songsPlaying.current === (songsPlayingIndex + 1)) {
                    songLoop(songsPlaying, songsPlayingIndex + 1);
                }
            }, 1000);
        };

        if (isPlaying) {
            if (songsPlaying.current === 0) {
                songsPlaying.current = 0;
                songLoop(songsPlaying, songsPlaying.current);
            }
        } else if (!isPlaying) {
            songsPlaying.current = 0;
        }
    }, [isPlaying, songsPlaying, songPercent, songPercentState, skipSong]);

    const displayTime = (time) => {
        let t = time;
        let display = "";
        if (t > 60) {
            display = display + Math.floor(t / 60) + ":";
            t = t % 60;
        } else {
            display = "0:"
        }
        if (t < 10) {
            display = display + 0 + t;
        } else {
            display += t;
        }
        return display;
    };

    const changeBackground = ( color, isPlaying ) => {
        let c;
        if (!color) {
            c = "linear-gradient(to bottom, #000000, #000000, #000000, #000000, #000000)";
        } else {
            c = color;
        }
        if (isPlaying) {
            gsap.to('.background-gradient-color', { backgroundImage: c, duration: 0.2, ease: 'sine.out' });
        } else {
            gsap.to('.background-gradient-color', { backgroundImage: "linear-gradient(to bottom, #000000, #000000, #000000, #000000, #000000)", duration: 0.3, ease: 'sine.out' });
        }
    };

    /*
    const skipSong = (dir) => {
        let i = songNum;
        if (dir === "forward") {
            if (songNum >= num_songs) {
                setSongNum(0);
                i = 0;
            } else {
                setSongNum(songNum + 1);
                i += 1;
            }
        } else if (dir === "backward") {
            if (songNum <= 0) {
                setSongNum(num_songs);
                i = num_songs;
            } else {
                setSongNum(songNum - 1);
                i -= 1;
            }
        } else {
            return;
        }
        songsPlaying.current = 0;
        songPercent.current = 0;
        setSongPercentState(0);
        setSelected(songs[i].title);
        setSelectedSubtitle(songs[i].subtitle);
        setSelectedSrc(songs[i].src);
        setSelectedDesc(songs[i].desc);
        setIsPlaying(true);
        changeBackground(songs[i].color, true);
    }
    */

    return (
        <div className="playlist-overlay-frame">
            <div className="playlist">
                <div style={{
                    paddingTop: "1.5rem",
                    padding: "1rem",
                }}>
                    <h1>Technical Skills</h1>
                </div>
                {songs.map((song, index) => (
                    <Song 
                        key={song.title}
                        setter={setter}
                        selected={selected}
                        src={song.src}
                        title={song.title}
                        subtitle={song.subtitle}
                        desc={song.desc}
                        index={index}
                        color={song.color}
                    />
                ))}
                <div style={{
                    padding: "1rem",
                }}>
                </div>
            </div>
            <div className="media-controls">
                <div style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "2rem",
                }}>
                    <button className="media-button"
                        onClick={() => {
                            skipSong("backward");
                        }}
                    >
                        <IoPlayBack size={36} />
                    </button>
                    <button className="media-button"
                        onClick={() => {
                            let playing;
                            if (!isPlaying) {
                                setIsPlaying(true);
                                playing = true;
                            } else {
                                setIsPlaying(false);
                                playing = false;
                            }
                            changeBackground(songs[songNum].color, playing);
                        }}
                    >
                        {isPlaying ? <FaPause size={36} /> : <FaPlay size={36} />}
                    </button>
                    <button className="media-button"
                        onClick={() => {
                            skipSong("forward");
                        }}
                    >
                        <IoPlayForward size={36} />
                    </button>
                </div>
                <div className="timeline" style={{
                    marginTop: "0.7rem",
                }}>
                    <p className="scrubber-time">{displayTime(Math.round(songTime * songPercentState / 100))}</p>
                    <div className="scrubber-frame">
                        <div className="scrubber" style={{ width: `${songPercentState}%` }}></div>
                    </div>
                    <p className="scrubber-time" style={{position: "relative", right: "10px"}}>{"-" + displayTime(Math.round(songTime * (1 - (songPercentState / 100))))}</p>
                </div>
            </div>
            <div className="now-playing">
                <div className="now-playing-frame">
                    <img
                        src={selectedSrc}
                        alt={selected}
                        style={{
                            width: "100%",
                            borderRadius: "8px",
                        }}
                    />
                    <h1 style={{
                        paddingTop: "0.8rem"
                    }}>
                        {selected}
                    </h1>
                    <h2 style={{
                        color: "#a3a3a3",
                        fontSize: "20px",
                        fontWeight: "600",
                    }}>
                        {selectedSubtitle}
                    </h2>
                    <p style={{
                        paddingTop: "1rem",
                        color: "#a3a3a3",
                        fontSize: "16px",
                        fontWeight: "400",
                    }}>
                        {selectedDesc}
                    </p>
                </div>
            </div>
        </div>
    );
};