import { useState } from "react";
import { ScrollControls } from "@react-three/drei";
import { Particles } from "./Particles";
import { Rig } from "./Rig";
import { Overlay } from "./Overlay";

function Experience() {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlaying = (val) => {
        setIsPlaying(val);
    };

    return (
        <>
            <ScrollControls pages={3} damping={0.25}>
                <Overlay isPlaying={isPlaying} setIsPlaying={handlePlaying}/>
                <Rig />
                <Particles isPlaying={isPlaying} />
            </ScrollControls>
        </>
    );
};

export default Experience;