import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

export default function HoverButton(props) {
    const buttonRef = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.set(buttonRef.current, { y: 0 });
            gsap.set(buttonRef.current, { color: "#ffffff" });
        });

        return () => ctx.revert();
    }, []);

    const onMouseEnter = ({ currentTarget }) => {
        let q = gsap.utils.selector(currentTarget);

        gsap.to(q(".btn-content"), { y: 2.2, duration: 0.2, ease: 'circ.out' });
        gsap.to(q(".btn-content"), { color: "#d8b1fd", duration: 0.1, ease: 'power1.out' });
        gsap.to(q(".icon"), { fill: "#d8b1fd", duration: 0.1, ease: 'power1.out' });
    };

    const onMouseLeave = ({ currentTarget }) => {
        let q = gsap.utils.selector(currentTarget);

        gsap.to(q(".btn-content"), { y: 0, duration: 0.2, ease: 'circ.out' });
        gsap.to(q(".btn-content"), { color: "#ffffff", duration: 0.1, ease: 'power1.in' });
        gsap.to(q(".icon"), { fill: "#ffffff", duration: 0.1, ease: 'power1.out' });
    };

    return (
        <div className="btn-container no-select" ref={buttonRef}>
            <a href={props.href} target={props.target} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div className="btn-content" ref={buttonRef}>
                    {props.children}
                </div>
            </a>
        </div>
    );
};