import { useState } from "react";
import HoverButton from "./HoverButton";

function Navbar() {
    const [isSticky, setIsSticky] = useState(false);

    window.onscroll = () => {
        if (window.scrollY >= 0) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    return (
        <nav className={"nav " + (isSticky ? "sticky" : "")}>
                <HoverButton href="/">
                    <p className="title">
                        JACHAREU©
                    </p>
                </HoverButton>
            <ul>
                <p>Software Dev</p>
                <p>Waterloo, ON</p>
            </ul>
        </nav>
    );
}

export default Navbar;