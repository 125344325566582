import { useRef, useState, useEffect } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

// components
// import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
// import { FaRegFaceDizzy } from "react-icons/fa6";
// import { FaYinYang } from "react-icons/fa6";

const CarouselContent = () => {
    return (
        <>
            {/* <div className="scroll-item">
                <FaRegFaceDizzy size={props.iconSize} />
            </div>
            <div className="scroll-item">
                <FaAnglesRight size={props.iconSize} />
            </div> */}

            <div className="scroll-item" style={{padding: "1rem"}}>
                <p>HELLO WORLD!</p>
            </div>

            {/* <div className="scroll-item">
                <FaAnglesLeft size={props.iconSize} />
            </div>
            <div className="scroll-item">
                <FaYinYang size={props.iconSize} />
            </div>
            <div className="scroll-item">
                <FaAnglesRight size={props.iconSize} />
            </div> */}

            <div className="scroll-item" style={{padding: "2rem"}}>
                <p>HELLO WORLD!</p>
            </div>
            {/* <div className="scroll-item">
                <FaAnglesLeft size={props.iconSize} />
            </div> */}
        </>
    );
};

export default function Carousel() {
    const [isMobile, setIsMobile] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const handleResize = () => {
            const w = window.innerWidth;
            //console.log(w);
            if (w <= 1024) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useGSAP(() => {
        gsap.fromTo(ref.current, {y: 400}, {y: 0, duration: 1.2, ease: "power2.out"}, 0.7);
    });

    return (
        <div className="scroller" ref={ref}>
            <ul className="tag-list scroller__inner cards">
                <CarouselContent iconSize={isMobile ? 70 : 112} />
                <CarouselContent iconSize={isMobile ? 70 : 112} />
            </ul>
        </div>
    );
};