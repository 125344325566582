import { shaderMaterial } from "@react-three/drei";
import { TextureLoader } from "three";
import { extend, useFrame, useLoader } from "@react-three/fiber";
import { useRef, useState, useLayoutEffect } from "react";
import { useScroll } from '@react-three/drei'
import gsap from 'gsap';

// assets
import texture from '../assets/paint_texture.png';

const ParticlesShaderMaterial = shaderMaterial(
    { time: 0, pointer_x: 0, pointer_y: 0, outline: false, uTexture: null, scroll: 0 },
    // vertex shader
    /*glsl*/`
        uniform float time;
        varying vec2 vUv;
        varying vec3 vPosition;
        varying vec3 vColor;
        uniform vec2 pixels;
        
        //	Simplex 3D Noise 
        //	by Ian McEwan, Stefan Gustavson (https://github.com/stegu/webgl-noise)
        //
        vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
        vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
        
        float snoise(vec3 v){ 
        const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
        const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
        
        // First corner
        vec3 i  = floor(v + dot(v, C.yyy) );
        vec3 x0 =   v - i + dot(i, C.xxx) ;
        
        // Other corners
        vec3 g = step(x0.yzx, x0.xyz);
        vec3 l = 1.0 - g;
        vec3 i1 = min( g.xyz, l.zxy );
        vec3 i2 = max( g.xyz, l.zxy );
        
        //  x0 = x0 - 0. + 0.0 * C 
        vec3 x1 = x0 - i1 + 1.0 * C.xxx;
        vec3 x2 = x0 - i2 + 2.0 * C.xxx;
        vec3 x3 = x0 - 1. + 3.0 * C.xxx;
        
        // Permutations
        i = mod(i, 289.0 ); 
        vec4 p = permute( permute( permute( 
                    i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
                + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
                + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
        
        // Gradients
        // ( N*N points uniformly over a square, mapped onto an octahedron.)
        float n_ = 1.0/7.0; // N=7
        vec3  ns = n_ * D.wyz - D.xzx;
        
        vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)
        
        vec4 x_ = floor(j * ns.z);
        vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
        
        vec4 x = x_ *ns.x + ns.yyyy;
        vec4 y = y_ *ns.x + ns.yyyy;
        vec4 h = 1.0 - abs(x) - abs(y);
        
        vec4 b0 = vec4( x.xy, y.xy );
        vec4 b1 = vec4( x.zw, y.zw );
        
        vec4 s0 = floor(b0)*2.0 + 1.0;
        vec4 s1 = floor(b1)*2.0 + 1.0;
        vec4 sh = -step(h, vec4(0.0));
        
        vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
        vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
        
        vec3 p0 = vec3(a0.xy,h.x);
        vec3 p1 = vec3(a0.zw,h.y);
        vec3 p2 = vec3(a1.xy,h.z);
        vec3 p3 = vec3(a1.zw,h.w);
        
        //Normalise gradients
        vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
        p0 *= norm.x;
        p1 *= norm.y;
        p2 *= norm.z;
        p3 *= norm.w;
        
        // Mix final noise value
        vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
        m = m * m;
        return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                        dot(p2,x2), dot(p3,x3) ) );
        }
        
        void main() {
            vColor = vec3(0.5);

            float tilt = -10.0 * uv.y;
            float incline = uv.x * 2.5;

            vec2 noiseCoord = uv*vec2(3.0, 4.0);
            float noise = snoise(vec3(noiseCoord.x + time * 2.0, noiseCoord.y, time * 5.0));
        
            vec3 pos = vec3(position.x - noise * 15.0, position.y + noise * 10.0, position.z + noise * 2.0 + tilt + incline);
        
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        }
    `,
    // fragment shader
    /*glsl*/`
        uniform sampler2D uTexture;
        uniform float time;
        uniform float pointer_x;
        uniform float pointer_y;
        uniform bool outline;
        varying vec2 vUv;
        varying vec3 vPosition;
        varying vec3 vColor;
        uniform vec2 pixels;
        uniform float scroll;
        void main() {
            vec4 color = texture2D(uTexture, vUv);

            if (outline) {
                gl_FragColor.rgba = vec4(1.0, 1.0, 1.0, 1.0);
            } else {
                if (scroll < 1.0) {
                    gl_FragColor.rgba = color + vec4(vUv + vec2(clamp(pointer_x * 0.2, 0.0, 0.5) + 0.3, clamp(pointer_y * 0.3, -0.2, 0.05)), 0.7, 1.0);
                } else {
                    gl_FragColor.rgba = color * vec4(1.0, 1.0, 1.0, 0.1) + vec4(0.1, 0.1, 0.1, 0.1);
                }
                /*gl_FragColor.rgba = color + vec4(vUv + vec2(clamp(pointer_x * 0.2, 0.0, 0.5) + 0.3, clamp(pointer_y * 0.3, -0.2, 0.05)), 0.7, 1.0);*/
            }
        }
    `
)

// declaratively
extend({ ParticlesShaderMaterial });


export function Particles({ isPlaying }) {
    const [time, setTime] = useState(0);
    const [step, setStep] = useState(0.0001);
    const [pointerX, setPointerX] = useState(0);
    const [pointerY, setPointerY] = useState(0);
    const particleRef = useRef();
    const outlineRef = useRef();
    const ref = useRef();
    const tl = useRef();
    const rtl = useRef();
    const scroll = useScroll();

    const uTexture = useLoader(TextureLoader, texture);

    useFrame((state) => {
        tl.current.seek(scroll.offset * 5/2 * tl.current.duration());
        rtl.current.seek(scroll.offset * rtl.current.duration());
        const timelimit = 0.4;
        let s = 0.03;
        if (time <= timelimit) {
            setTime(time + s);
            particleRef.current.scale.x += s / timelimit;
            particleRef.current.scale.y += s / timelimit;
            particleRef.current.scale.z += s / timelimit;
            outlineRef.current.scale.x += s / timelimit * 1.16;
            outlineRef.current.scale.y += s / timelimit * 1.10;
            outlineRef.current.scale.z += s / timelimit * 1.08;
            s *= 0.02;
        } else {
            setTime(time + step);
        }

        setPointerX(state.pointer.x);
        setPointerY(state.pointer.y);

        if (scroll.range(0, 1/3) < 1) {
            setStep(0.0001 + scroll.range(1/3 * 0.4, 1/3) * 0.033);
        } else {
            if (isPlaying) {
                const max = 0.025;
                if (step < max) {
                    setStep(step * 1.2);
                } else if (step > max) {
                    setStep(max);
                }
            } else {
                const min = 0.0004;
                if (step > min) {
                    setStep(step * 0.85);
                } else if (step < min) {
                    setStep(min);
                }
            }
        }
    });

    useLayoutEffect(() => {
        tl.current = gsap.timeline();
        rtl.current = gsap.timeline();
    
        // scale down
        tl.current.to(
            ref.current.scale, 
            {
                duration: 2,
                x: 0.0001,
                y: 0.0001,
                z: 0.0001,
            },
            0
        );

        // scale up
        rtl.current.to(
            ref.current.scale,
            {
                duration: 2,
                x: 0.7,
                y: 0.7,
                z: 0.7,
            },
            2.8
        );
    }, []);
    

    return (
        <group ref={ref}>
            <mesh ref={outlineRef} position={[0, -30, 0]} rotation={[-Math.PI/2, 0 * Math.PI/3, 0]} scale={[0, 0, 0]}>
                <sphereGeometry args={[17, 64, 64]}/>
                <particlesShaderMaterial time={time} pointer_x={pointerX} pointer_y={pointerY} outline={true}/>
            </mesh>
            <mesh ref={particleRef} position={[0, -25, 0]} rotation={[-Math.PI/2, 0 * Math.PI/3, 0]} scale={[0, 0, 0]}>
                <sphereGeometry args={[17, 64, 64]}/>
                <particlesShaderMaterial time={time} pointer_x={pointerX} pointer_y={pointerY} outline={false} uTexture={uTexture} scroll={scroll.range(0, 0.5)}/>
            </mesh>
        </group>
    );
};